import { Icon, IconProps } from "@chakra-ui/react";

export const FigmaIcon = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="34"
    viewBox="0 0 24 34"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4514 2.64591H6.54863C4.39322 2.64591 2.64591 4.39322 2.64591 6.54863C2.64591 8.70405 4.39322 10.4514 6.54863 10.4514H10.4514V2.64591ZM10.4514 0H13.0973H17C20.6168 0 23.5485 2.93191 23.5485 6.54863C23.5485 8.68293 22.5277 10.5788 20.9473 11.7743C22.5277 12.9699 23.5485 14.8657 23.5485 17C23.5485 20.6167 20.6168 23.5486 17 23.5486C15.5376 23.5486 14.1872 23.0693 13.0973 22.2592V23.5486V27.4514C13.0973 31.0681 10.1653 34 6.54863 34C2.93191 34 0 31.0681 0 27.4514C0 25.3169 1.02101 23.4212 2.60125 22.2257C1.02101 21.0301 0 19.1343 0 17C0 14.8657 1.02103 12.9699 2.6013 11.7743C1.02103 10.5788 0 8.68293 0 6.54863C0 2.93191 2.93191 0 6.54863 0H10.4514ZM13.0973 2.64591V10.4514H17C19.1555 10.4514 20.9026 8.70405 20.9026 6.54863C20.9026 4.39322 19.1555 2.64591 17 2.64591H13.0973ZM6.54863 20.9027H10.4514V17.0101V17V16.99V13.0973H6.54863C4.39322 13.0973 2.64591 14.8446 2.64591 17C2.64591 19.1503 4.38486 20.8944 6.53315 20.9027L6.54863 20.9027ZM2.64591 27.4514C2.64591 25.301 4.38486 23.557 6.53315 23.5486L6.54863 23.5486H10.4514V27.4514C10.4514 29.6067 8.70405 31.3541 6.54863 31.3541C4.39322 31.3541 2.64591 29.6067 2.64591 27.4514ZM13.0973 16.9922C13.1015 14.8404 14.8472 13.0973 17 13.0973C19.1555 13.0973 20.9026 14.8446 20.9026 17C20.9026 19.1554 19.1555 20.9027 17 20.9027C14.8472 20.9027 13.1015 19.1596 13.0973 17.0078V16.9922Z"
      fill="currentColor"
    />
  </Icon>
);
