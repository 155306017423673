import { Icon, IconProps } from "@chakra-ui/react";

export const GitIcon = (props: IconProps) => (
  <Icon
    width="35"
    height="38"
    viewBox="0 0 35 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.3596 36.009V29.7496C24.4203 28.9784 24.3161 28.2031 24.0539 27.4752C23.7918 26.7473 23.3777 26.0836 22.8392 25.5282C27.9179 24.9621 33.2554 23.0374 33.2554 14.2063C33.255 11.9481 32.3863 9.77651 30.8293 8.14098C31.5666 6.16532 31.5144 3.98156 30.6837 2.04334C30.6837 2.04334 28.7751 1.47724 24.3596 4.43711C20.6525 3.43241 16.7448 3.43241 13.0377 4.43711C8.62217 1.47724 6.71363 2.04334 6.71363 2.04334C5.88288 3.98156 5.83075 6.16532 6.56806 8.14098C4.99937 9.78864 4.12985 11.9799 4.14194 14.2548C4.14194 23.0212 9.4794 24.9459 14.5581 25.5767C14.0259 26.1266 13.6156 26.7823 13.3536 27.5013C13.0917 28.2203 12.984 28.9863 13.0377 29.7496V36.009"
      fill="none"
    />
    <path
      d="M13.0377 31.1568C4.95065 33.5829 4.95065 27.1132 1.71582 26.3045M24.3596 36.009V29.7496C24.4203 28.9784 24.3161 28.2031 24.0539 27.4752C23.7918 26.7473 23.3777 26.0836 22.8392 25.5282C27.9179 24.9621 33.2554 23.0374 33.2554 14.2063C33.255 11.9481 32.3863 9.77651 30.8293 8.14098C31.5666 6.16532 31.5144 3.98156 30.6837 2.04334C30.6837 2.04334 28.7751 1.47724 24.3596 4.43711C20.6525 3.43241 16.7448 3.43241 13.0377 4.43711C8.62217 1.47724 6.71363 2.04334 6.71363 2.04334C5.88288 3.98156 5.83075 6.16532 6.56806 8.14098C4.99937 9.78864 4.12985 11.9799 4.14194 14.2548C4.14194 23.0212 9.4794 24.9459 14.5581 25.5767C14.0259 26.1266 13.6156 26.7823 13.3536 27.5013C13.0917 28.2203 12.984 28.9863 13.0377 29.7496V36.009"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
